@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight:400;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
    /* font-family: "Poppins", sans-serif; */
}
.li::after {
  content: " ";
  display: block;

  width: 0%;
  height: 1px;

  background-color: #000;

  transition: width 0.4s;
}

.li:hover::after {
  width: 100%;
}
.mid::after {
  margin: 0 auto;
}


.clients {
    /* height: 100vh; */
    /* background-color:#c9c9c9; */
    display: grid;
    /* place-items: center; */
}

section {
    display: grid;
    place-items: center;
}



.slider {
  padding: 50px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* width: 80%; */
    overflow: hidden;
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.4px);
    -webkit-backdrop-filter: blur(7.4px);
    border: 1px solid rgba(255, 255, 255, 0.4);
}

.slider-items  {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    animation: scrolling 20s linear infinite;


}
.slider-items1{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  animation: scrollingright 20s linear infinite;
}

@keyframes scrolling {

    0% {
        transform: translateX(80%);
    }

    100% {
        transform: translateX(-20%);
    }
}
@keyframes scrollingright {

  0% {
      transform: translateX(-20%);
  }

  100% {
      transform: translateX(80%);
  }
}

.slider-items img ,.slider-items1 img {
    width: 12%;
    margin: 20px;

}
.wrapper {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  /* height: 400px; */
  /* display: flex; */
  flex-wrap: nowrap;
  justify-content: start;
}
/* styles.css */
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.gb-shutter{
  border: 2px solid var(--gb-51);
  border-radius: 5px;
  box-shadow: 3px 3px 0 var(--gb-51);
  /* font-size: 1.125rem; */
  font-weight: 700;
  overflow: hidden;
  color: var(--gb-51);
}
.gb-shutter::after, .gb-shutter::before{
  top: 0;
  left: 0;
  width: 130%;
  aspect-ratio: 1;
  background: var(--gb-51);
}
.gb-shutter::after{
  transform: translate(-100%,-80%) rotate(30deg);
}
.gb-shutter::before{
  transform: translate(100%,30%) rotate(30deg);
}
.gb-shutter:hover::after{
  transform: translate(-50%,-50%) rotate(30deg);
}
.gb-shutter:hover::before{
  transform: translate(30%,-10%) rotate(30deg);
}
.gb-shutter:hover{
  color: #eee;
  box-shadow: 0 0 0;
}
.gb-cutter::before,
.gb-shutter::before,
.gb-shutter::after,
.gb-bordered::before,
.gb-split::before
{
    content: "";
    position: absolute;
    z-index: -1;
    transition: 0.5s;
}
:root{
  --gb-51: #2E7D32;
  --gb-52: #2196f3;
  --gb-52d: #0e71c1;
}
.gm-btn{
  background: none;
  border:1px solid #2E7D32;
  /* padding: 5px 15px; */
  /* font-size: 16px; */
  line-height: 24px;
  /* width: 150px; */
  /* height: 40px; */
  transition: 0.3s;
  cursor: pointer;
  position: relative;
  outline: none;
}

.card {
  /* padding-bottom: 10px; */
  /* margin-bottom: 10px; */
  width: 80px;
  border-radius: .75rem;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
  border-radius: 2rem;
  margin: 20px 10px;
  display: flex;
  align-items: flex-end;
  transition: .6s cubic-bezier(.28,-0.03,0,.99);
  box-shadow: 0px 10px 30px -5px rgba(0,0,0,0.8);
}

.card > .row {
  color: white;
  display: flex;
  flex-wrap: nowrap;
}

.card > .row > .icon {
  background: #223;
  color: white;
  border-radius: 50%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
}
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.card > .row > .description {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  height: 80px;
  width: 520px;
  opacity: 0;
  transform: translateY(30px);
  transition-delay: .3s;
  transition: all .3s ease;
}

.description p {
  color: #b0b0ba;
  padding-top: 5px;
}

.description h4 {
  text-transform: uppercase;
}

input {
  display: none;
}

input:checked + label {
  width: 600px;
}

input:checked + label .description {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.card[for="c1"] {
  background-image: url('https://images.unsplash.com/photo-1453306458620-5bbef13a5bca?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}
.card[for="c2"] {
  background-image: url('https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}
.card[for="c3"] {
  background-image: url('https://plus.unsplash.com/premium_photo-1661421687248-7bb863c60723?q=80&w=2138&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}
.card[for="c4"] {
  background-image: url('https://images.unsplash.com/photo-1485827404703-89b55fcc595e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}
.card[for="c5"] {
  background-image: url('https://images.unsplash.com/photo-1485827404703-89b55fcc595e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}

#flip-card {
  
    /* background-color: transparent; */
    perspective: 1000px;
    /* font-family: sans-serif; */
  }
  #flip-card-inner {
    
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  #flip-card:hover #flip-card-inner {
    
    transform: rotateY(180deg);
  }
  
  #flip-card-front, #flip-card-back {
    box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    /* border: 1px solid rgb(5, 162, 5); */
    border-radius: 1rem;
  }
  
  #flip-card-front {
    background-position: -50px;
  }
  
  #flip-card-back {
    /* background:linear-gradient(to top right,rgba(0, 128, 0, 0.62),rgb(5,162,5,0.3),white); */
    border: 1px solid #e9a003;
    transform: rotateY(180deg);
  }
  
  #box {
    box-sizing: border-box;
    /* width: 150px; */
    /* height: 50px; */
    border: 3px solid #e9a003;
    /* font-family: 'Cinzel', serif; */
    /* font-size: 20px; */
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    transition: 1s;
  }
  
  #box::before, #box::after {
    position: absolute;
    background: white;
    z-index: -1;
    transition: 1s;
    content: '';
  }
  
  #box::before {
    height: 450px;
    width: 300px;
  }
  
  #box::after {
    width: 470px;
    height: 330px;
  }
  
  #box img {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
  }
  
  #box:hover::before {
    width: 0px;
    background: #fff;
  }
  
  #box:hover::after {
    height: 0px;
    background: #fff;
  }
  .cursor {
    background-color: #111;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    /* pointer-events: none; */
  }
  
  #box:hover {
    background: #fff;
  }
  h2 {
    color: #2E7D32;
    font-family: "Ranchers", sans-serif;
    font-weight: 400;
    letter-spacing: 5px;
    font-style: normal;
  }
  .section {
    /* margin: 3rem; */
    padding: 3rem;
    /* background-color: red; */
  }
  
  .title {
    padding: 1rem 2rem;
    background-color: #2E7D32;
    margin-left: -6rem;
    height: fit-content;
    padding-top: 15px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    color: white;
    width: 100%;
    /* font-size: 50px; */
    /* position: relative; */
    filter: drop-shadow(8px 8px 8px rgba(0, 0, 0, .3));
  }
  .title::after {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: #2B5C96;
    left: 0;
    bottom: -1rem;
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 100%,
      0% 0%
    );
  }
  .title1 {
    padding: 1rem 2rem;
    background-color: #2E7D32;
    /* margin-left: 20rem; */
    height: fit-content;
    padding-top: 15px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    color: white;
    width: 100%;
    /* font-size: 50px; */
    position: relative;
    filter: drop-shadow(8px 8px 8px rgba(0, 0, 0, .3));
  }
  .title1::after {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: #2B5C96;
    left: 98.7%;
  /* right: 100px; */
    bottom: -15px;
    clip-path: polygon(
      100% 0%,
      0% 0%,
      0% 0%,
      100% 100%
    );
  }
  .home{
    /* background-image: url('./assets/images/pixlr-image-generator-e7e4d9a8-9aa4-4240-be3d-05a868d47efb.png'); */
    background-size:cover;
    /* background-position: center; */
    background-position-y: -100px;
    background-repeat: no-repeat;
    
    /* background-position: right; */
    background-image:linear-gradient(to bottom,rgb(0, 0, 0),rgba(255, 255, 255, 0)),url('./assets/images/OLPWT20.jpg');
  }
  /* src/Parallax.css */
  .parallax-container {
    position: relative;
    overflow: hidden;
    height: 100vh; /* Full viewport height */
  }

.parallax-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  z-index: -1; /* Behind the content */
}

.parallax-content {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  text-align: center;
}


.container1 {
  position : relative;
  /* width : 1100px; */
  /* display : flex; */
  align-items : center;
  justify-content : center;
  /* flex-warp : warp; */
  padding : 30px;  
}

.container1 .card1 {
  position: relative;
  max-width : 300px;
  height : 215px;  
  background-color : rgb(15, 124, 57);
  margin : 50px 10px;
  padding : 20px 15px;
  
  display : flex;
  flex-direction : column;
  box-shadow : 0 5px 20px rgba(0,0,0,0.5);
  transition : 0.3s ease-in-out;
  border-radius : 15px;
}
.container1 .card1:hover {
  height : 370px;    
}


.container1 .card1 .image {
  position : relative;
  width : 260px;
  height : 260px;
  
  top : -40%;
  left: 8px;
  box-shadow : 0 5px 20px rgba(0,0,0,0.2);
  z-index : 1;
}

.container1 .card1 .image img {
  max-width : 100%;
  width: fit-content;
  
  border-radius : 15px;
}

.container1 .card1 .content {
  position : relative;
  top : -140px;
  padding : 10px 15px;
  color : #111;
  text-align : center;
  
  visibility : hidden;
  opacity : 0;
  transition : 0.3s ease-in-out;
    
}

.container1 .card1:hover .content {
   margin-top : 30px;
   visibility : visible;
   opacity : 1;
   transition-delay: 0.2s;
  
}
@media (max-width:568px){
  .title{
    width: 1350px;
    /* margin-left: -150px; */
    padding-top: 30px;
    height: max-content;
    border-top-right-radius: 1000px ; 
    border-bottom-right-radius: 1000px ; 
  }
  .title1{
    width: 1440px;
    /* margin-left: -150px; */
    height: fit-content;
    border-top-left-radius: 1000px ; 
    border-bottom-left-radius: 1000px ; 
  }
  .title1::after{
    left: 99%;
  }
  .parallax-container {
    position: relative;
    overflow: hidden;
    height: 350vh; /* Full viewport height */
  }
  .slider{
    height: 600px;
  }
  .slider-items img{
    width: 30%;
    
    
  }
  .slider-items1 img{
    width: 30%;
    
    
  }
  .container1 .card1{
    max-width: 800px;
    height: 400px;
    margin: 100px 10px;
  }
  .container1 .card1 .image{
    width: 800px;
    height: 500px;
    /* left: 10%; */
  }
  .container1 .card1 .image img{
    max-width: 1000px;
  }
  .container1 .card1:hover {
    height : 700px;    
  }
  .container{
    height: 2000px;
  }
}